import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApiInterceptor } from "@ds-interceptors/api-interceptor.interceptor";
import { GlobalErrorHandlerService } from "@ds-common-services/utility-services/global-error-handler.service";
import { NgxJsonapiModule } from "ngx-jsonapi";
import { environment } from "@ds-env/environment";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { LoginComponent } from "@ds-public-layouts/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

// Import Services
// Import Servicesends

import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { SharedModule } from "@ds-shared/shared.module";
import { ResetPasswordComponent } from "./layouts/public-layouts/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./layouts/public-layouts/forgot-password/forgot-password.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ResetPasswordComponent,
		ForgotPasswordComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		PerfectScrollbarModule,
		LoadingBarHttpClientModule,
		InfiniteScrollModule,
		SharedModule,
		NgxJsonapiModule.forRoot({
			url: environment.APIENDPOINT
		}),
		NgbModule,
		ToastrModule.forRoot({
			positionClass: "toast-top-right",
			closeButton: true,
			// preventDuplicates: true
			// progressBar: true,
			timeOut: 2500
		})
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
		{ provide: ErrorHandler, useClass: GlobalErrorHandlerService },
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
